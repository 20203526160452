import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import EmailConfirmation from "../components/EmailConfirmation"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Home from "../pages/Home"
import NotFound from "../pages/NotFound"
import PrivacyPolicy from "../pages/PrivacyPolicy"
import PrescriptionAudit from "../pages/PrescriptionAudit"
import TermsOfUse from "../pages/TermsOfUse"
import AboutUs from "../pages/AboutUs"
import ContactUs from "../pages/ContactUs"

function AppRoutings() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/terms-and-conditions" component={TermsOfUse} />
        <Route path="/user/confirm" component={EmailConfirmation} />
        <Route path="/prescription-audit" component={PrescriptionAudit} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  )
}

export default AppRoutings
