import React, { useEffect, useRef, useState } from "react"
import TopSectionPrescriptionAudit from "../components/TopSectionPrescriptionAudit"
import UserDetailsForm from "../components/UserDetailsForm"

function PrescriptionAudit() {
  const [formValue, setFormValue] = useState("")
  const [otpErrorMessage, setOtpErrorMessage] = useState("")
  const [isEmail, setIsEmail] = useState(false)
  const [disabledForm, setDisabledForm] = useState(false)
  const [userId, setUserId] = useState(undefined)
  const [directPrescription, setDirectPrescription] = useState(false)

  const userDetailsRef = useRef(null)

  let savedUserId = localStorage.getItem("userId")

  useEffect(() => {
    if (disabledForm && userDetailsRef?.current) {
      userDetailsRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      })
    }
  }, [userDetailsRef, disabledForm])

  useEffect(() => {
    if (savedUserId && savedUserId.trim() && !disabledForm) {
      setUserId(savedUserId.trim())
      setDisabledForm(true)
      setDirectPrescription(true)
    }
  }, [savedUserId, disabledForm])

  return (
    <div>
      <TopSectionPrescriptionAudit
        setOtpErrorMessage={setOtpErrorMessage}
        isEmail={isEmail}
        setIsEmail={setIsEmail}
        formValue={formValue}
        setFormValue={setFormValue}
        disabledForm={disabledForm}
        setDisabledForm={setDisabledForm}
        setUserId={setUserId}
        userDetailsRef={userDetailsRef}
      />
      {disabledForm && <UserDetailsForm otpErrorMessage={otpErrorMessage} isEmail={isEmail} userDetailsRef={userDetailsRef} parentData={formValue} userId={userId} setUserId={setUserId} directPrescription={directPrescription} />}
    </div>
  )
}

export default PrescriptionAudit
