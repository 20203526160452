import React from "react"

function AboutUs() {
  return (
    <div className="main-wrapper contents-wrapper">
      <div className="section-heading">About Us</div>
      <h3>Who We Are</h3>
      <p>
        At TheDocPro, we are pioneers in delivering cutting-edge content infrastructure solutions tailored specifically for the healthcare industry. With a deep understanding of the unique challenges and opportunities within the healthcare sector, we
        have crafted a robust platform that empowers healthcare companies to manage, distribute, and optimize their content seamlessly. Our solutions are designed to enhance communication, foster engagement, and drive better health outcomes through
        the power of digital content. Our aim is to strengthen your digital presence by promoting branded content, product releases, and a lot more.
      </p>
      <h3>Our Journey</h3>
      <p>
        Our journey began with a simple yet profound realization: the healthcare industry is at a pivotal point in its digital transformation. The need for accurate, timely, and accessible content has never been greater. However, we observed a
        significant gap in the market—healthcare companies were struggling with outdated systems, fragmented content strategies, and the challenge of complying with stringent regulatory requirements. We founded TheDocPro to bridge this gap and
        provide a comprehensive solution that addresses the specific needs of healthcare content management.
      </p>
      <p>
        Since our inception, we have grown into a trusted partner for numerous healthcare organizations, ranging from pharmaceutical companies to hospitals and clinics. Our platform has evolved to incorporate the latest technological advancements,
        ensuring that our clients stay ahead of the curve in a rapidly changing digital landscape.
      </p>
      <h3>Our Vision</h3>
      <p>
        <strong>Transforming Healthcare Communication for a Better Tomorrow</strong>
      </p>
      <p>
        Our vision is to revolutionize the way healthcare companies create, manage, and share content. We believe that effective communication is the cornerstone of quality healthcare, and our goal is to empower healthcare organizations with the
        tools they need to deliver precise, impactful, and compliant content to their audiences. By leveraging innovative technology and a deep understanding of the healthcare sector, we aim to be the leading content infrastructure provider that
        drives positive change in healthcare communication globally.
      </p>
      <h3>Our Mission</h3>
      <p>
        <strong>To Empower Healthcare Companies with World-Class Content Solutions</strong>
      </p>
      <p>
        At TheDocPro, Our mission is to provide healthcare organizations with the most advanced, user-friendly, and compliant content infrastructure. We are committed to helping our clients overcome the complexities of content management in the
        healthcare industry, enabling them to focus on what they do best—providing exceptional care to their patients. Through our platform, we strive to enhance the efficiency, accuracy, and reach of healthcare content, ultimately contributing to
        better patient outcomes and a healthier world.
      </p>
      <h3>What We Do</h3>
      <p>
        At TheDocPro, we offer a comprehensive suite of services designed to meet the diverse content needs of healthcare companies. Our platform is built on a foundation of flexibility, scalability, and compliance, ensuring that our clients can
        manage their content with ease and confidence.
      </p>
      <p>
        <strong>1. Content Management System (CMS)</strong>
      </p>
      <p>
        Our CMS is the backbone of our platform, providing healthcare organizations with a centralized hub for all their content needs. Whether you're managing clinical documentation, patient education materials, or marketing content, our CMS offers
        intuitive tools to organize, edit, and distribute content efficiently.
      </p>
      <p>
        <strong>2. Multi-Channel Content Distribution</strong>
      </p>
      <p>
        Healthcare content must reach diverse audiences across multiple channels. Our platform supports seamless content distribution across websites, social media, mobile apps, and more. Whether you're communicating with healthcare professionals,
        patients, or the general public, our multi-channel distribution capabilities ensure that your content is delivered accurately and consistently. TheDocPro saves pharmaceuticals the hassle of setting up and maintaining an online content
        infrastructure. Since the platform is loaded with state-of-the art features, your team can focus on marketing and engagement, while we handle the technical side!
      </p>
      <p>
        <strong>3. Healthcare insights</strong>
      </p>
      <p>
        In today's digital age, data is king. Through our proprietary service MetaRx, we aim to provide powerful analytics to healthcare providers about their prescribing patterns to ensure compliance with clinical guidelines, improve patient safety,
        and enhance treatment outcomes. The service analyzes prescription data to identify trends, deviations, and areas for improvement. It helps in optimizing medication use, reducing errors, and ensuring that prescriptions align with best
        practices and regulatory standards. Additionally, it can provide insights into cost-saving opportunities and support adherence to formulary requirements, ultimately contributing to better patient care and streamlined healthcare operations
      </p>
      <h3>Why Choose Us?</h3>
      <p>
        <strong>Expertise in Healthcare</strong>
      </p>
      <p>
        At TheDocPro, we specialize in healthcare content. Our team consists of experts with deep industry knowledge, ensuring that our platform meets the unique needs of healthcare organizations. We understand the challenges you face and are
        committed to providing solutions that address these challenges head-on.
      </p>
      <p>
        <strong>Commitment to Innovation</strong>
      </p>
      <p>
        We are constantly evolving our platform to incorporate the latest technological advancements. Our commitment to innovation ensures that you have access to the most advanced tools and features available, keeping you ahead of the competition in
        a rapidly changing digital landscape.
      </p>
      <p>
        <strong>Focus on Compliance</strong>
      </p>
      <p>
        Compliance is at the core of everything we do. Our platform is designed to help you navigate the complex regulatory environment of the healthcare industry with ease. From automated approval workflows to comprehensive audit logs, we provide
        the tools you need to ensure your content is always compliant.
      </p>
      <p>
        <strong>Customer-Centric Approach</strong>
      </p>
      <p>
        We believe that our success is measured by the success of our clients. That's why we take a customer-centric approach to everything we do. From personalized onboarding to ongoing support, we are here to help you achieve your goals and
        maximize the value of our platform.
      </p>
    </div>
  )
}

export default AboutUs
