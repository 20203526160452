import React, { useEffect, useState } from "react"
import BookADemo from "./BookADemo"
import { MailOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import { MetaRxLogo } from "../utilities"
import { NavLink } from "react-router-dom"

function Footer() {
  const [visible, setVisible] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const rootEle = document.getElementById("root")
    const footerEle = document.getElementById("footer")

    if (footerEle) {
      rootEle.style.paddingBottom = footerEle.clientHeight + 16 + "px"
    }
  }, [])

  return history.location.pathname.includes("/prescription-audit") ? null : (
    <footer id="footer">
      <img src={MetaRxLogo} alt="logo" className="footerLogo" />
      <button onClick={() => setVisible(true)} className="bookDemoButton bookDemoButtonBlue">
        Send Your Query
      </button>
      <div className="sitemap">
        <NavLink to="/about-us">About Us</NavLink>
        <NavLink to="/contact-us">Contact Us</NavLink>
        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
      </div>
      <section className="footerGrid">
        <span className="footerContact">
          <span>Contact us:</span>
          <MailOutlined style={{ margin: "0 4px" }} />
          <a href="mailto:info@thedoc.pro">info@metarx.in</a>
        </span>
        <span className="copyright">
          Copyright © {new Date().getFullYear()}-{new Date().getFullYear() + 1} All Rights Reserved
        </span>
      </section>
      <BookADemo visible={visible} setVisible={setVisible} />
    </footer>
  )
}

export default Footer
