import React from "react"
import {Collapse} from "antd"

const {Panel} = Collapse

function PrivacyPolicy() {
    return (
        <div className="main-wrapper contents-wrapper">
            <div className="section-heading">Privacy Policy</div>
            <p>
                Welcome to MetaRx, a service provided by DocPro, dedicated to securely capturing and managing
                prescriptions. You are advised to read the Privacy Policy carefully. This Privacy Policy explains how we
                collect, use, and protect your personal and health-related information when you access or use our
                services, whether through our website, mobile application, or any other digital means (collectively,
                "Platform"). This Privacy Policy applies to current and former visitors of the Platform and all users of
                the Platform, whether registered or not. By accessing or using this Platform on any computer, mobile
                phone, tablet, console or other device (collectively, "Device"), you consent to our Privacy Policy.
            </p>
            <p>For the purposes of this Privacy Policy, "we", "our" and "us" shall mean MetaRx (TheDocPro) and "you" and
                "your" shall mean any visitor to or user of the Platform.
            </p>

            <div className="contents-collapse">
                <Collapse accordion defaultActiveKey={[""]} ghost expandIconPosition="right">
                    <Panel header="INFORMATION THAT IS OR MAY BE COLLECTED FROM YOU" key="1">
                        <p>We may collect the following personally identifiable information, including but not limited
                            to:</p>
                        <ul>
                            <li>name including first and last name;</li>
                            <li>Gender;</li>
                            <li>Date Of Birth;</li>
                            <li>Email Address;</li>
                            <li>Mobile Number and Contact Details;</li>
                            <li>Your registration number with the relevant regulatory authority for medical education
                                and such other information as we may request from you from time to time.
                            </li>
                        </ul>
                        <p>We may also collect information pertaining:</p>
                        <ul>
                            <li>the links you click on this Platform;</li>
                            <li>the number of times you access the Platform’s page/portal;</li>
                            <li>the number of times you have interacted on this Platform;</li>
                            <li>the prescriptions details including medication names, dosage, frequency, and duration;
                            </li>
                            <li>the diagnosis and treatment history as provided by healthcare professionals;</li>
                            <li>the pharmacy preferences;</li>
                        </ul>
                    </Panel>
                    <Panel header="METHOD OF COLLECTING INFORMATION" key="2">
                        <p>We may collect information from you passively. We use tracking tools including, but not
                            limited to, Google Analytics, Google Webmaster, browser cookies and web beacons for
                            collecting information about your usage of the Platform.</p>
                        <p>
                            We may get information about you from third parties. For example, if you were to use an
                            integrated email login feature to sign-up to the Platform, the third-party email service
                            provider will give us certain information about you. We
                            shall, however, use all such information in accordance with this Privacy Policy.
                        </p>
                    </Panel>
                    <Panel header="METHOD OF USING INFORMATION" key="3">
                        <p>We may use your personal information for reasons including, but not limited to, the
                            following:</p>
                        <ul>
                            <li>to capture, store, and manage your prescription information securely;</li>
                            <li>to share your prescriptions with healthcare providers or pharmacies as authorized by
                                you;
                            </li>
                            <li>to tailor our services to your specific health needs and preferences;</li>
                            <li>administer contests, promotions, events, surveys or other features;</li>
                            <li>to preserve browsing history as governed by applicable law or policy;</li>
                            <li>administer contests, promotions, events, surveys or other features;</li>
                            <li>for security purposes: to protect our company, other users or our Platform and
                                applications;
                            </li>
                            <li>to recommend relevant healthcare services or products based on your prescription
                                history.
                            </li>
                        </ul>
                        <p>We use contact information internally to:</p>
                        <ul>
                            <li>to send you confirmations, acknowledgments and other details pertaining to continuing
                                medical education courses completed by you or that may be of interest to you;
                            </li>
                            <li>direct our efforts for improvement of the Platform and its contents;</li>
                            <li>contact you as a survey respondent;</li>
                            <li>notify you of any developments in this Privacy Policy; and</li>
                            <li>
                                send you promotional materials regarding: (i) upcoming events; (ii) newly uploaded
                                Platform content; and/or (iii) any information that we deem material in order for you to
                                be a fully informed user of and visitor to the Platform.
                            </li>
                        </ul>
                        <p>We may use your information as otherwise permitted by law.</p>
                    </Panel>
                    <Panel header="LEGAL BASIS FOR COLLECTING AND PROCESSING INFORMATION" key="4">
                        <p>We collect and process information about users like you who use our Platform. We consider
                            that we have a legal basis to do so where:</p>
                        <ul>
                            <li>
                                you have given us consent to do so for the specific purposes of using our Platform
                                pursuant to an agreement entered into by your company or yourself with us, wherein we
                                will process your data to fulfill the terms of the said
                                agreement;
                            </li>
                            <li>it is necessary for us to do so to enable us to provide you with the services that you
                                have requested from us in using the Platform;
                            </li>
                            <li>
                                it is necessary in order to fulfil our legitimate interests (or those of a third party)
                                to protect the safety and security of our services and Platform, operating and improving
                                the Platform, supporting our customers, marketing and
                                promoting the Platform, and protecting our interests; or
                            </li>
                            <li>the law otherwise permits or requires it.</li>
                        </ul>
                    </Panel>
                    <Panel header="SHARING OF INFORMATION" key="5">
                        <p>Your personal data will only be seen or used by our employees or contractors who have a
                            legitimate business need to access such information personal data for the purposes set out
                            in this Privacy Policy.</p>
                        <p>
                            We take your privacy seriously and have implemented appropriate physical, technical and
                            organizational security measures designed to secure your personal data against accidental
                            loss, destruction or damage and unauthorized access, use,
                            alteration or disclosure.
                        </p>
                        <p>
                            We may also share your personal data with our affiliates and third-party service providers,
                            to enable the efficient and secure provision of services to you. Except as set forth in the
                            Terms of Use, we do not rent, sell or share your
                            personal information and we will not disclose any of your personally identifiable
                            information to third parties unless:
                        </p>
                        <ul>
                            <li>we have your permission; or</li>
                            <li>it is with organizations or companies who may contact you for the purpose of furthering
                                your continuing medical education requirements; or
                            </li>
                            <li>it is to provide products or services you have requested; or</li>
                            <li>
                                it is to help investigate, prevent or take action regarding unlawful and illegal
                                activities, suspected fraud, potential threat to the safety or security of any person,
                                violations of our Terms of Use or to defend against legal claims;
                                or
                            </li>
                            <li>it is with a successor to all or part of our business; or</li>
                            <li>special circumstances such as compliance with court orders, requests/order, notices from
                                legal authorities or law enforcement agencies compel us to make such disclosure; or
                            </li>
                            <li>it forms a part of the anonymous information we share with our partners and/or
                                advertisers on an aggregate basis
                            </li>
                        </ul>
                        <p>We may share your information for reasons not described herein. However, the same shall be
                            done only upon obtaining your consent.</p>
                    </Panel>
                    <Panel header="THIRD PARTY APPLICATIONS" key="6">
                        <p>
                            You may be directed to third-party links or applications from our Platform which we do not
                            control. This Privacy Policy solely and exclusively governs your usage and experience of our
                            Platform. Accordingly, we undertake no liability
                            whatsoever as regards your usage of these third-party applications and websites and you may
                            visit them at your own risk. We strongly encourage that you read the privacy policies of any
                            third-party link or application or website you are
                            directed to before using the same.
                        </p>
                    </Panel>
                    <Panel header="DISABLING COOKIES" key="7">
                        <p>
                            If you would like to disable our access to any passive information we receive from the use
                            of various technologies, you may choose to disable cookies in your web browser, which may
                            affect the complete use of our Platform, products and
                            services. Please be aware that TheDocPro will still receive information about your usage and
                            also your personally identifiable information, such as your email address. If you choose to
                            delete your account, TheDocPro will delete your
                            registration information from the active databases. Please note that even upon deletion of
                            account, we may maintain certain information for product improvement purposes as per the
                            data regulation laws of the given geography at the given
                            time.
                        </p>
                    </Panel>
                    <Panel header="DATA RETENTION POLICY" key="8">
                        <p>We will keep your data for as long as we provide access to our Platform. We will then keep
                            data for a limited period when we no longer provide services to you to deal with any queries
                            or complaints or as required under law.</p>
                    </Panel>

                    <Panel header="PROCEDURE FOR CORRECTING INACCURACIES IN THE INFORMATION" key="11">
                        <p>To correct or update any information you have provided, you may do so online. In the event of
                            loss of login credentials, you can use any of the following options:</p>
                        <ul>
                            <li>
                                Send an e-mail to: [<a href="mailto:info@thedoc.pro">info@thedoc.pro</a>] or [<a
                                href="mailto:info@metarx.in">info@metarx.in</a>]
                            </li>
                            <li>
                                Call the following telephone number: <a href="tel:+917406197778">[+91-7406197778]</a> or
                            </li>
                        </ul>
                    </Panel>
                    <Panel header="PRIVACY POLICY UPDATES" key="12">
                        <p>
                            We reserve the right to change or update this Privacy Policy at any time. Such changes shall
                            be effective immediately upon posting on this Platform. We will notify you of any material
                            changes as required by law, but we advise to
                            periodically check the Platform for updates.
                        </p>
                    </Panel>
                    <Panel header="GRIEVANCE OFFICER" key="13">
                        <p>In accordance with the Indian Information Technology Act, 2000 and the rules made thereunder,
                            the name and contact details of the grievance redressal officer are provided below:</p>
                        <ul>
                            <li>Mr. Sharath K K</li>
                            <li>4th Floor, Saroj Bank Square, No 228/114/4 Boganahalli, Bengaluru - 560103</li>
                            <li>+91-7406197778</li>
                            <li>
                                <a href="mailto:info@thedoc.pro">info@thedoc.pro</a>
                                <a href="mailto:info@metarx.in">info@metarx.in</a>
                            </li>
                        </ul>
                        <p>
                            If you have any other questions about this Privacy Policy or other privacy concerns, you can
                            also email us at [<a href="mailto:info@thedoc.pro">info@thedoc.pro</a>] or [<a
                            href="mailto:info@metarx.in">info@metarx.in</a>].
                        </p>
                    </Panel>
                    <Panel header="JURISDICTION" key="14">
                        <p>
                            Regardless of where you live, you consent to have your personal data transferred, processed
                            and stored in India or other parts of the world, as may be required to perform our
                            obligations hereunder, in compliance with applicable laws.
                            Any disputes arising under this Privacy Policy shall be governed by the laws of India and
                            the courts at Bengaluru, India shall have exclusive jurisdiction.
                        </p>
                    </Panel>
                    <Panel header="TERMS OF USE" key="15">
                        <p>
                            Your use of and access to the Platform and any disputes arising therefrom, is subject to
                            this Privacy Policy as well as our Terms of Use. Please read our Terms of Use carefully in
                            order to obtain information on the terms governing the
                            use of and access to the Platform.{" "}
                        </p>
                    </Panel>
                </Collapse>
            </div>
        </div>
    )
}

export default PrivacyPolicy
