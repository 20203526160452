import axios from "axios"
import {getDataOptions, postDataOptions} from "./backendServices"
import {BASE_URL, COMMONS_SERVICE_BASE_URL} from "./environments"

//Apis
const BOOK_DEMO_API = "mail/book/demo"
const COUNTRIES_API = "api/location/countries"
const VERIFY_EMAIL_API = "api/login/confirm?token={tokenId}"

// Services
export const bookDemoService = async (payload) => {
    try {
        const response = await axios(postDataOptions(BASE_URL, BOOK_DEMO_API, payload))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const verifyEmail = async (token) => {
    try {
        const data = {
            token: token,
        }
        const url = VERIFY_EMAIL_API.replace("{tokenId}", token)
        const response = await axios(postDataOptions(BASE_URL, url, data))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getCountriesService = async () => {
    try {
        const response = await axios(getDataOptions(BASE_URL, COUNTRIES_API))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const sendOtpService = async (data) => {
    try {
        const response = await axios(postDataOptions(COMMONS_SERVICE_BASE_URL, "prescription-audit/send-otp", data))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const userCreateService = async (data) => {
    try {
        const response = await axios(postDataOptions(COMMONS_SERVICE_BASE_URL, "prescription-audit/user-create", data))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const otpVerificationService = async (userId, otp) => {
    try {
        const response = await axios(postDataOptions(COMMONS_SERVICE_BASE_URL, `prescription-audit/otp-verification?userId=${userId}&otp=${otp}`))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const userAdditionalFieldService = async (userId, data) => {
    try {
        const response = await axios(postDataOptions(COMMONS_SERVICE_BASE_URL, `prescription-audit/user-additional-fields/${userId}`, data, true))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAllSpecialitiesService = async () => {
    try {
        const response = await axios(getDataOptions(COMMONS_SERVICE_BASE_URL, "common/getAllSpecialities"))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAllRegionsOfIndiaService = async () => {
    try {
        const response = await axios(getDataOptions(COMMONS_SERVICE_BASE_URL, "common/getAllRegionsOfIndia"))
        return response.data
    } catch (error) {
        return Promise.reject(error)
    }
}
