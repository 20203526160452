import React from "react"
import {Fragment} from "react/cjs/react.production.min"
import {isAlphabets, isNumberic} from "../utilities"
import {PAYMENT_OPTIONS} from "./UserDetailsForm";

function UserBankDetailsForm({
                                 bankFromsData,
                                 handleBankFormsData,
                                 accountNumberError,
                                 handleAccountNumberBlur,
                                 setPaymentChecked
                             }) {
    return (
        <Fragment>
            <div className="form-items">
                <label style={{display: 'flex', alignItems: 'center'}}>Account Name
                    <div
                        style={{
                            color: 'gray',
                            fontSize: '12px',
                            display: 'flex',
                            marginLeft: '8px',
                            marginTop: '2px'
                        }}>or
                        <div onClick={() => setPaymentChecked(PAYMENT_OPTIONS.CHEQUE)}
                             className={'link-button'}
                             style={{
                                 color: 'gray',
                                 fontSize: '12px',
                                 marginLeft: '4px'
                             }}>Upload <span style={{color: '#1E88C9'}}>Blank Cheque</span>
                        </div>
                    </div>
                </label>
                <input type="text" placeholder="Enter Bank Account Name" name="bankAccountName"
                       value={bankFromsData.bankAccountName} onChange={handleBankFormsData} onKeyDown={isAlphabets}
                       className="form-controls"/>
            </div>
            <div className="form-items">
                <label>Bank Name</label>
                <input type="text" placeholder="Enter Bank Name" name="bankName" value={bankFromsData.bankName}
                       onChange={handleBankFormsData} onKeyDown={isAlphabets} className="form-controls"/>
            </div>
            <div className="form-items">
                <label>Account Number</label>
                <input type="text" placeholder="Enter Account Number" name="accountNumber"
                       value={bankFromsData.accountNumber} onChange={handleBankFormsData} onKeyDown={isNumberic}
                       onBlur={handleAccountNumberBlur} className="form-controls"/>
            </div>
            <div className="form-items">
                <label>Confirm Account Number</label>
                <input
                    type="text"
                    placeholder="Enter Confirm Account Number"
                    name="confirmAccountNumber"
                    value={bankFromsData.confirmAccountNumber}
                    onChange={handleBankFormsData}
                    onKeyDown={isNumberic}
                    onBlur={handleAccountNumberBlur}
                    className="form-controls"
                />
                {accountNumberError !== "" &&
                    <div style={{color: "red", marginTop: 4, fontSize: 14}}>{accountNumberError}</div>}
            </div>
            <div className="form-items">
                <label>IFSC Code</label>
                <input type="text" placeholder="Enter IFSC Code" name="ifscCode" value={bankFromsData.ifscCode}
                       onChange={handleBankFormsData} className="form-controls"/>
            </div>
        </Fragment>
    )
}

export default UserBankDetailsForm
