import {Alert, Checkbox, Image, Modal, Select} from "antd"
import React, {useEffect, useState} from "react"
import OtpInput from "react-otp-input"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import ArrowIcon from "../assets/images/ArrowIconWhite.svg"
import AppLogo from "../assets/images/favicon.ico"
import {LoadingOutlined} from "@ant-design/icons"
import {isPdf, isValidEmail, validateUPI} from "../utilities"
import {
    getAllRegionsOfIndiaService,
    getAllSpecialitiesService,
    otpVerificationService,
    sendOtpService,
    userAdditionalFieldService,
    userCreateService
} from "../services/apiServices"
import {MdClose, MdOutlineAttachFile, MdRemoveRedEye, MdVerified} from "react-icons/md"
import {IoMdCloseCircle} from "react-icons/io"
import TickIcon from "../assets/images/TickIconGreen.svg"
import UserBankDetailsForm from "./UserBankDetailsForm"

const {Option} = Select

export const PAYMENT_OPTIONS = {
    UPI: 1,
    BANK: 2,
    CHEQUE: 3
}

function UserDetailsForm({
                             otpErrorMessage,
                             isEmail,
                             parentData,
                             userDetailsRef,
                             userId,
                             setUserId,
                             directPrescription
                         }) {
    const [otp, setOtp] = useState("")
    const [formValue, setFormValue] = useState("")
    const [formsData, setFormsData] = useState({
        firstName: "",
        lastName: "",
        qualification: "",
        speciality: null,
        medicalCouncil: null,
        registrationNumber: "",
    })
    const [loading, setLoading] = useState(false)
    const [showOtp, setShowOtp] = useState(false)
    const [otpStatus, setOtpStatus] = useState(1)
    const [userCreateErrorMessage, setUserCreateErrorMessage] = useState("")
    const [otpVerificationErrorMessage, setOtpVerificationErrorMessage] = useState("")
    const [showAdditionalFields, setShowAdditionalFields] = useState(false)
    const [files, setFiles] = useState({})
    const [upiId, setUpiId] = useState("")
    const [checked, setChecked] = useState(false)
    const [additionalFieldSubmitLoading, setAdditionalFieldSubmitLoading] = useState(false)
    const [additionalFieldsSubmitErrorMessage, setAdditionalFieldsSubmitErrorMessage] = useState("")
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false)
    const [seePreivew, setSeePreview] = useState(false)
    const [seePreivewPan, setSeePreviewPan] = useState(false)
    const [seePreviewCheque, setSeePreviewCheque] = useState(false)
    const [allSpecialities, setAllSpecialities] = useState([])
    const [allRegionsOfIndia, setAllRegionsOfIndia] = useState([])
    const [upiErrorMessage, setUpiErrorMessage] = useState("")
    const [paymentChecked, setPaymentChecked] = useState(1)
    const [bankFromsData, setBankFormsData] = useState({
        bankName: "",
        bankAccountName: "",
        accountNumber: "",
        ifscCode: ""
    })
    const [accountNumberError, setAccountNumberError] = useState("")

    let userName = localStorage.getItem("userName")
    let isPanCardRequired = localStorage.getItem("isPanCardRequired") && localStorage.getItem("isPanCardRequired") === "true"

    const sendOtp = () => {
        setLoading(true)
        const payload = isEmail ? {email: parentData.trim()} : {mobileNumber: parentData.trim().substring(2)}
        sendOtpService(payload)
            .then((response) => {
                if (response.hasUploaded) {
                    window.location.reload()
                } else {
                    setUserId(response.userId)
                    localStorage.setItem("userName", response.userName)
                    localStorage.setItem("isPanCardRequired", response.isPanCardRequired)
                }
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const handleUserCreate = () => {
        setLoading(true)
        const payload = isEmail
            ? {
                firstName: formsData.firstName.trim(),
                lastName: formsData.lastName.trim(),
                email: parentData.trim(),
                mobileNumber: formValue.substring(2),
                qualification: formsData.qualification.trim(),
                speciality: formsData.speciality,
                medicalCouncil: formsData.medicalCouncil,
                registrationNumber: formsData.registrationNumber.trim(),
            }
            : {
                firstName: formsData.firstName.trim(),
                lastName: formsData.lastName.trim(),
                email: formValue.trim(),
                mobileNumber: parentData.substring(2),
                qualification: formsData.qualification.trim(),
                speciality: formsData.speciality,
                medicalCouncil: formsData.medicalCouncil,
                registrationNumber: formsData.registrationNumber.trim(),
            }

        userCreateService(payload)
            .then((response) => {
                setLoading(false)
                setShowOtp(true)
                setUserId(response.userId)
                localStorage.setItem("userName", response.userName)
                localStorage.setItem("isPanCardRequired", response.isPanCardRequired)
            })
            .catch((error) => {
                setLoading(false)
                if (error.response?.data?.message) {
                    setUserCreateErrorMessage(error.response.data.message)
                }
            })
    }

    const validateForm = () => {
        return (
            !formsData.firstName ||
            !formsData.firstName.trim() ||
            !formsData.lastName ||
            !formsData.lastName.trim() ||
            !formValue ||
            !formValue.trim() ||
            !formsData.qualification ||
            !formsData.qualification.trim() ||
            !formsData.speciality ||
            !formsData.medicalCouncil ||
            !formsData.registrationNumber ||
            !formsData.registrationNumber.trim() ||
            (!isEmail ? !formValue || !isValidEmail(formValue) : !formValue || formValue.length < 12)
        )
    }

    const validateBankForm = () => {
        if (paymentChecked === PAYMENT_OPTIONS.BANK) {
            return !bankFromsData.bankName || !bankFromsData.bankAccountName || !bankFromsData.accountNumber || bankFromsData.accountNumber !== bankFromsData.confirmAccountNumber || !bankFromsData.ifscCode
        }
    }

    useEffect(() => {
        if (otp.length === 6 && userId) {
            setOtpStatus(2)
            otpVerificationService(userId, otp)
                .then((response) => {
                    setOtpStatus(3)
                })
                .catch((error) => {
                    setOtpStatus(4)
                    if (error.response?.data?.message) {
                        setOtpVerificationErrorMessage(error.response?.data?.message)
                    }
                })
        }
    }, [otp, userId])

    useEffect(() => {
        if (otpStatus === 3 && showAdditionalFields) {
            localStorage.setItem("userId", userId)
        }
    }, [otpStatus, showAdditionalFields])

    useEffect(() => {
        getAllSpecialities()
        getAllRegionsOfIndia()
    }, [])

    const handleMultipleFilesUpload = (ev, name) => {
        const fileList = []
        let files = ev.target.files

        if (files?.length > 0) {
            for (let i = 0; i < files.length; i++) {
                fileList.push({fileName: files[i].name, file: files[i], fileUrl: URL.createObjectURL(files[i])})
            }
        }

        if (fileList.length > 0) {
            setFiles({...files, [name]: fileList})
        }
    }

    const handleFilesUpload = (ev, name) => {
        let file = ev.target.files[0]
        if (file) {
            setAdditionalFieldsSubmitErrorMessage("")
            setFiles({...files, [name]: {fileName: file.name, file: file, fileUrl: URL.createObjectURL(file)}})
        }
    }

    const getAppropriateObject = (fileName, fileUrl, customHeight = "400") => {
        return isPdf(fileName) ? (
            <iframe src={fileUrl + "#toolbar=0&navpanes=0&scrollbar=0&view=FitH"}
                    className={`width-full height-${customHeight}`} title="PDF Viewer"/>
        ) : (
            <Image src={fileUrl} style={{borderRadius: "12px"}} preview={false} className="width-full height-full"/>
        )
    }

    const fileUploadObjectView = (uploadObject, apiUrl) => {
        return (
            <div style={{width: "100%", margin: "8px 0"}}>
                {uploadObject?.fileName ? <div className="lines-1 opacity-5 mb-4">{uploadObject.fileName}</div> : ""}
                {(uploadObject?.fileUrl || apiUrl) && getAppropriateObject(uploadObject?.fileName ? uploadObject.fileName : apiUrl, uploadObject?.fileUrl ? uploadObject.fileUrl : apiUrl)}
            </div>
        )
    }

    const handleChecked = (e) => {
        setChecked(e.target.checked)
        setShowAdditionalFields(true)
    }

    const handleAdditionalFieldsSubmit = () => {
        if (paymentChecked === PAYMENT_OPTIONS.UPI && !validateUPI(upiId)) {
            setUpiErrorMessage("Please enter valid UPI ID")
            return
        }
        setAdditionalFieldSubmitLoading(true)
        const formData = new FormData()
        if (files.file?.length > 0) {
            for (let i = 0; i < files.file.length; i++) {
                formData.append("prescriptions", files.file[i].file)
            }
        }
        if (files.cheque?.file) {
            formData.append("cheque", files.cheque.file)
        }
        if (isPanCardRequired && files.pan?.file) {
            formData.append("panCard", files.pan.file)
        }
        let payload = paymentChecked === PAYMENT_OPTIONS.BANK ? bankFromsData : PAYMENT_OPTIONS.UPI ? {upiId: upiId} : {}
        formData.append(
            "prescriptionUserAddFieldRequest",
            new Blob([JSON.stringify(payload)], {
                type: "application/json",
            })
        )
        userAdditionalFieldService(userId, formData)
            .then((response) => {
                setAdditionalFieldSubmitLoading(false)
                setOpenPrescriptionModal(true)
                localStorage.clear()
            })
            .catch((error) => {
                setAdditionalFieldSubmitLoading(false)
                if (error.response?.data?.message) {
                    setAdditionalFieldsSubmitErrorMessage(error.response?.data?.message)
                }
            })
    }

    const handleFormsData = (ev) => {
        const {name, value} = ev.target
        setFormsData({...formsData, [name]: value})
    }

    const handleChangeSelection = (value, name) => {
        setFormsData({...formsData, [name]: value})
    }

    const handleChangeUpiId = (ev) => {
        setUpiErrorMessage("")
        setUpiId(ev.target.value)
    }

    const handleClose = () => {
        window.location.reload()
        setOpenPrescriptionModal(false)
    }

    const handleSeePreview = (ev) => {
        ev.preventDefault()
        setSeePreview(true)
    }

    const handleSeePreviewPan = (ev) => {
        ev.preventDefault()
        setSeePreviewPan(true)
    }

    const handleSeePreviewCheque = (ev) => {
        ev.preventDefault()
        setSeePreviewCheque(true)
    }

    const getAllSpecialities = () => {
        getAllSpecialitiesService()
            .then((response) => setAllSpecialities(response.specialities))
            .catch((error) => console.error(error))
    }

    const getAllRegionsOfIndia = () => {
        getAllRegionsOfIndiaService()
            .then((response) => setAllRegionsOfIndia(response.regions))
            .catch((error) => console.error(error))
    }

    const handleBankFormsData = (ev) => {
        const {name, value} = ev.target
        setBankFormsData({...bankFromsData, [name]: value})
    }

    const handleAccountNumberBlur = () => {
        if (bankFromsData.confirmAccountNumber?.length >= 10) {
            if (bankFromsData.accountNumber !== bankFromsData.confirmAccountNumber) {
                setAccountNumberError("Account Numbers does not matching!")
            } else {
                setAccountNumberError("")
            }
        }
    }

    return (
        <div ref={userDetailsRef} className="user-details-form-container">
            {otpErrorMessage !== "" && <Alert message={otpErrorMessage} type="warning" showIcon/>}
            {otpErrorMessage ? (
                <div className="user-details-form" style={userId ? {cursor: "no-drop", opacity: 0.5} : {}}>
                    <div className="form-items">
                        <label>First Name</label>
                        <input type="text" placeholder="Enter first name" name="firstName" value={formsData.firstName}
                               onChange={handleFormsData} className="form-controls"/>
                    </div>
                    <div className="form-items">
                        <label>Last Name</label>
                        <input type="text" placeholder="Enter last name" name="lastName" value={formsData.lastName}
                               onChange={handleFormsData} className="form-controls"/>
                    </div>
                    {isEmail ? (
                        <div className="form-items">
                            <label>Mobile Number</label>
                            <div style={{position: "relative"}} className="mobile-form-controls">
                                <PhoneInput country={"in"} countryCodeEditable={false} disableDropdown={true}
                                            value={formValue} onChange={setFormValue}
                                            placeholder="Enter mobile number"/>
                                {!formValue && <span className="place-holder-text">Enter mobile number</span>}
                            </div>
                        </div>
                    ) : (
                        <div className="form-items">
                            <label>Email Address</label>
                            <input type="email" placeholder="Enter email address" value={formValue}
                                   onChange={(ev) => setFormValue(ev.target.value)} className="form-controls"/>
                        </div>
                    )}
                    <div className="form-items">
                        <label>Qualification</label>
                        <input type="text" placeholder="Enter qualification" name="qualification"
                               value={formsData.qualification} onChange={handleFormsData} className="form-controls"/>
                    </div>
                    <div className="form-items">
                        <label>Speciality</label>
                        <Select
                            allowClear
                            showSearch
                            placeholder="Select a speciality"
                            optionFilterProp="children"
                            className="form-controls"
                            onChange={(value) => handleChangeSelection(value, "speciality")}
                            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                        >
                            {allSpecialities.map((items, index) => {
                                return (
                                    <Option key={index} value={items.name}>
                                        {items.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="form-items">
                        <label>Medical Council</label>
                        <Select
                            allowClear
                            showSearch
                            placeholder="Select a medical council"
                            optionFilterProp="children"
                            className="form-controls"
                            onChange={(value) => handleChangeSelection(value, "medicalCouncil")}
                            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                        >
                            {allRegionsOfIndia.map((items, index) => {
                                return (
                                    <Option key={index} value={items.name}>
                                        {items.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="form-items">
                        <label>MCI Number</label>
                        <input type="text" placeholder="Enter MCI Number" name="registrationNumber" value={formsData.registrationNumber}
                               onChange={handleFormsData} className="form-controls"/>
                    </div>
                    <div className="form-items">
                        <label className="empty-label">&nbsp;</label>
                        <button onClick={handleUserCreate} disabled={validateForm()}
                                className="bookDemoButton bookDemoButtonBlue submit-button">
                            Continue to verify {loading ? <LoadingOutlined style={{marginLeft: 16}}/> :
                            <img src={ArrowIcon} alt/>}
                        </button>
                    </div>
                </div>
            ) : directPrescription ? (
                ""
            ) : (
                <div>
                    <Alert message={`An SMS and email with your OTP have been sent. Please enter it to verify.`}
                           type="success" showIcon/>
                    <div className="otp-controls">
                        <OtpInput value={otp} onChange={setOtp} numInputs={6} renderInput={(props) => <input
                            disabled={otpStatus === 3 && showAdditionalFields} {...props} />}/>
                        {otpStatus > 1 && (
                            <div style={{fontSize: "14px", display: "flex"}}>
                                {otpStatus === 2 ? (
                                    <LoadingOutlined style={{marginLeft: 16}}/>
                                ) : otpStatus === 3 ? (
                                    <div className={"otp-status"} style={{color: "green"}}>
                                        <span>Verified</span>
                                        <MdVerified style={{marginLeft: 8}}/>
                                    </div>
                                ) : (
                                    <>
                                        <div className={"otp-status"} style={{color: "red"}}>
                                            <span>Incorrect</span>
                                            <IoMdCloseCircle style={{marginLeft: 8}}/>
                                        </div>
                                        <button onClick={sendOtp} className="green-button" style={{marginTop: 8}}>
                                            Re-send OTP {loading ? <LoadingOutlined style={{marginLeft: 16}}/> :
                                            <img src={ArrowIcon} alt/>}
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <Checkbox value={checked} onChange={handleChecked}
                                  disabled={otpStatus === 3 && showAdditionalFields} style={{marginRight: 8}}>
                            I hereby acknowledge that I have read, understood, and agree to the{" "}
                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer"
                               style={{fontWeight: "500"}}>
                                terms and conditions
                            </a>
                            .
                        </Checkbox>
                    </div>
                </div>
            )}

            {!otpErrorMessage ? null : userCreateErrorMessage !== "" ? (
                <Alert message={userCreateErrorMessage} type="error" showIcon/>
            ) : showOtp ? (
                <div>
                    <Alert message={`An SMS and email with your OTP have been sent. Please enter it to verify.`}
                           type="success" showIcon/>
                    <div className="otp-controls">
                        <OtpInput value={otp} onChange={setOtp} numInputs={6} renderInput={(props) => <input
                            disabled={otpStatus === 3 && showAdditionalFields} {...props} />}/>
                        {otpStatus > 1 && (
                            <div style={{fontSize: "14px"}}>
                                {otpStatus === 2 ? (
                                    <LoadingOutlined style={{marginLeft: 16}}/>
                                ) : otpStatus === 3 ? (
                                    <div className={"otp-status"} style={{color: "green"}}>
                                        <span>Verified</span>
                                        <MdVerified style={{marginLeft: 8}}/>
                                    </div>
                                ) : (
                                    <div className={"otp-status"} style={{color: "red"}}>
                                        <span>Incorrect</span>
                                        <IoMdCloseCircle style={{marginLeft: 8}}/>
                                        <button onClick={sendOtp} className="green-button">
                                            Re-send OTP {loading ? <LoadingOutlined style={{marginLeft: 16}}/> :
                                            <img src={ArrowIcon} alt/>}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <Checkbox onChange={handleChecked} disabled={otpStatus === 3 && showAdditionalFields}
                                  style={{marginRight: 8}}>
                            I hereby acknowledge that I have read, understood, and agree to the{" "}
                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer"
                               style={{fontWeight: "500"}}>
                                terms and conditions
                            </a>
                            .
                        </Checkbox>
                    </div>
                </div>
            ) : null}

            {((otpStatus === 3 && showAdditionalFields) || directPrescription) && (
                <div className={"upload-section"}>
                    {userName && (
                        <h4>
                            Dear <span className="blue-text">{userName}</span>, we sincerely appreciate your valuable
                            time and participation in the
                        </h4>
                    )}
                    <div className={"section-heading"}>Prescription Audit</div>
                    <div className="user-details-form">
                        <div className="file-upload">
                            <div className="file-upload-label-text"
                                 style={files.file ? {color: "green", display: "flex", alignItems: "center"} : {}}>
                                <span>Prescriptions Upload</span> {files.file && <MdVerified style={{marginLeft: 8}}/>}
                            </div>
                            <input type="file" id="upload-prescription" multiple="multiple"
                                   accept=".jpg,.jpeg,.png,.pdf" hidden
                                   onChange={(ev) => handleMultipleFilesUpload(ev, "file")}/>
                            <label style={{display: "flex", alignItems: "center"}} for="upload-prescription">
                                <MdOutlineAttachFile style={{fontSize: "16px", marginRight: 4}}/> Choose Prescriptions
                            </label>
                            {files.file?.length > 0 && (
                                <div className="uploaded-file-info">
                  <span className="uploaded-preview" onClick={handleSeePreview}>
                    <MdRemoveRedEye/>
                    See preview {files.file?.length > 0 ? `(${files.file.length})` : null}
                  </span>
                                </div>
                            )}
                        </div>
                        {isPanCardRequired && (
                            <div className="file-upload">
                                <div className="file-upload-label-text"
                                     style={files.pan ? {color: "green", display: "flex", alignItems: "center"} : {}}>
                                    <span>PAN Upload</span> {files.pan && <MdVerified style={{marginLeft: 8}}/>}
                                </div>
                                <input type="file" id="upload-pan" accept=".jpg,.jpeg,.png,.pdf" hidden
                                       onChange={(ev) => handleFilesUpload(ev, "pan")}/>
                                <label style={{display: "flex", alignItems: "center"}} for="upload-pan">
                                    <MdOutlineAttachFile style={{fontSize: "16px", marginRight: 4}}/> Choose PAN
                                </label>
                                {files.pan && (
                                    <div className="uploaded-file-info">
                    <span className="uploaded-preview" onClick={handleSeePreviewPan}>
                      <MdRemoveRedEye/>
                      See preview
                    </span>
                                    </div>
                                )}
                            </div>
                        )}
                        {paymentChecked === PAYMENT_OPTIONS.BANK ?
                            <UserBankDetailsForm bankFromsData={bankFromsData} handleBankFormsData={handleBankFormsData}
                                                 accountNumberError={accountNumberError}
                                                 handleAccountNumberBlur={handleAccountNumberBlur}
                                                 setPaymentChecked={setPaymentChecked}/> :
                            paymentChecked === PAYMENT_OPTIONS.UPI ? <div className="form-items">
                                <label style={{display: 'flex', alignItems: 'center'}}>UPI ID <div
                                    style={{
                                        color: 'gray',
                                        fontSize: '12px',
                                        display: 'flex',
                                        marginLeft: '8px',
                                        marginTop: '2px'
                                    }}>or
                                    <div onClick={() => setPaymentChecked(PAYMENT_OPTIONS.BANK)}
                                         className={'link-button'}
                                         style={{
                                             color: 'gray',
                                             fontSize: '12px',
                                             marginLeft: '4px'
                                         }}>Use <span style={{color: '#1E88C9'}}>Bank Details</span>
                                    </div>
                                </div>
                                </label>
                                <input type="text" placeholder="Enter UPI ID"
                                       className="form-controls" value={upiId} onChange={handleChangeUpiId}/>
                                {upiErrorMessage !== "" &&
                                    <div style={{color: "red", marginTop: 4, fontSize: 14}}>{upiErrorMessage}</div>}
                            </div> : <div className="file-upload">
                                <div className={'file-upload-label-text'}
                                     style={{display: 'flex', alignItems: 'center'}}>Upload Cheque <div
                                    style={{
                                        color: 'gray',
                                        fontSize: '12px',
                                        display: 'flex',
                                        marginLeft: '8px',
                                        marginTop: '2px'
                                    }}>or
                                    <div onClick={() => setPaymentChecked(PAYMENT_OPTIONS.UPI)}
                                         className={'link-button'}
                                         style={{
                                             color: 'gray',
                                             fontSize: '12px',
                                             marginLeft: '4px'
                                         }}>Use <span style={{color: '#1E88C9'}}>UPI</span>
                                    </div>
                                </div>
                                </div>
                                <input type="file" id="upload-pan" accept=".jpg,.jpeg,.png,.pdf" hidden
                                       onChange={(ev) => handleFilesUpload(ev, "cheque")}/>
                                <label style={{display: "flex", alignItems: "center"}} htmlFor="upload-pan">
                                    <MdOutlineAttachFile style={{fontSize: "16px", marginRight: 4}}/> Choose File
                                </label>
                                {files.cheque && (
                                    <div className="uploaded-file-info">
                    <span className="uploaded-preview" onClick={handleSeePreviewCheque}>
                      <MdRemoveRedEye/>
                      See preview
                    </span>
                                    </div>
                                )}
                            </div>}
                    </div>
                    <div className="form-items">
                        <label className="empty-label">&nbsp;</label>
                        <button
                            onClick={handleAdditionalFieldsSubmit}
                            disabled={!files.file || (isPanCardRequired && !files.pan) || (paymentChecked === PAYMENT_OPTIONS.UPI && !upiId) || (paymentChecked === PAYMENT_OPTIONS.CHEQUE && !files.cheque) || (paymentChecked === PAYMENT_OPTIONS.UPI && !upiId.trim()) || validateBankForm()}
                            className="bookDemoButton bookDemoButtonBlue submit-button"
                        >
                            Submit {additionalFieldSubmitLoading ? <LoadingOutlined style={{marginLeft: 16}}/> :
                            <img src={ArrowIcon} alt/>}
                        </button>
                    </div>
                    {additionalFieldsSubmitErrorMessage !== "" &&
                        <Alert message={additionalFieldsSubmitErrorMessage} type="error" showIcon/>}
                    <div style={{marginTop: 16}}>
                        <span style={{fontWeight: "bold", color: "#1E88C9"}}>Note : </span>
                        Kindly upload a patient prescription for <span style={{fontWeight: "bold", color: "#1E88C9"}}>infection treatment</span>,
                        and provide your UPI ID to facilitate the processing of the honorarium.
                    </div>
                </div>
            )}
            <Modal title={""} footer={null} open={openPrescriptionModal} onOk={handleClose} onCancel={handleClose}
                   closeIcon={<MdClose/>} className="bookingModal" width={370} centered>
                <section className="thankYouMessage">
                    <img src={TickIcon} alt=""/>
                    <h2>Thank you for submitting the prescription!</h2>
                </section>
            </Modal>
            <Modal title="Uploaded Prescription" footer={null} open={seePreivew} onOk={() => setSeePreview(false)}
                   onCancel={() => setSeePreview(false)}>
                <div style={{margin: "-8px 0"}}>
                    {files.file?.length > 0 &&
                        files.file.map((items) => {
                            return fileUploadObjectView(items, null)
                        })}
                </div>
            </Modal>
            <Modal title="Uploaded PAN Card" footer={null} open={seePreivewPan} onOk={() => setSeePreviewPan(false)}
                   onCancel={() => setSeePreviewPan(false)}>
                <div style={{margin: "-8px 0"}}>{fileUploadObjectView(files.pan, null)}</div>
            </Modal>
            <Modal title="Uploaded Cheque" footer={null} open={seePreviewCheque} onOk={() => setSeePreviewCheque(false)}
                   onCancel={() => setSeePreviewCheque(false)}>
                <div style={{margin: "-8px 0"}}>{fileUploadObjectView(files.cheque, null)}</div>
            </Modal>
            <img src={AppLogo} className={"rx-logo"} alt={"MetaRx"}/>
        </div>
    )
}

export default UserDetailsForm
