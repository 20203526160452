import React from "react"
import ContactImg from "../assets/images/customer-support.webp"
import { MailOutlined, PhoneOutlined } from "@ant-design/icons"

function ContactUs() {
  return (
    <div className="main-wrapper contents-wrapper">
      <div className="section-heading">Contact Us</div>
      <p>
        If you have any questions or would like to learn more about our services, please don't hesitate to get in touch. Our team is here to help you navigate the complexities of healthcare content management and find the solutions that are right for
        you.
      </p>
      <div className="contact-section">
        <img src={ContactImg} alt="" className="contact-banner" />
        <div className="contact-info">
          <h4>Electronics Mail</h4>
          <a href="mailto:info@metarx.in">
            <MailOutlined style={{ marginRight: 8 }} />
            info@metarx.in
          </a>
          <h4>Phone Support</h4>
          <a href="tel:+917406197778">
            <PhoneOutlined style={{ transform: "rotate(-250deg)", marginRight: 8 }} />
            +91 - 7406197778
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
