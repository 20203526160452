import React from 'react';
import Brands from '../components/Brands';
import Sections from '../components/Sections';
import TopSection from '../components/TopSection';
import DashboardImg1 from '../assets/images/dashboard-1.png';
import DashboardImg2 from '../assets/images/dashboard-2.png';

const contents = {
  title: 'Building a prescription audit has never been easy',
  description:
    'MetaRx takes the complexity out of prescription audits for pharmaceuticals. With advanced features at your fingertips, your team can focus on enhancing patient care and compliance, while we manage the audit intricacies!',
  points: [
    { title: 'Gather prescriptions from doctors effortlessly, and let MetaRx provide detailed analysis to enhance clinical decision-making' },
    { title: 'Transform prescription data into valuable, actionable insights that drive better patient outcomes and optimized treatment strategies' },
    { title: 'Leverage a robust platform with top-tier security and 99.99% uptime, ensuring consistent, trustworthy audit processes' },
  ],
};

const contents2 = {
  title: 'About time you gain broader and deeper insights with MetaRx',
  description: 'You get a comprehensive dashboard to monitor the performance of your prescription audits and track the engagement of healthcare providers, offering detailed insights for each analysis and report.',
  points: [
    { title: 'Gain detailed insights into doctors\' prescribing patterns and their impact on patient outcomes, all in one place' },
    { title: 'Monitor critical metrics such as prescription trends and compliance rates to refine strategies and improve patient care' },
    { title: 'Access a consolidated view of feedback from healthcare providers, helping you continuously improve the audit process and results' },
  ],
};

function Home() {
  return (
    <div>
      <TopSection />
      {/*<Brands />*/}
      <div id='features' style={{ transitionDuration: '1000ms' }}>
        <Sections title={contents.title} description={contents.description} points={contents.points} media={DashboardImg1} className='contentsBg' showUptillText={true} />
      </div>
      <Sections title={contents2.title} description={contents2.description} points={contents2.points} media={DashboardImg2} showUptillText={false} />
    </div>
  );
}

export default Home;
