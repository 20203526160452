import React from 'react';
import Lottie from 'react-lottie';
import Image404 from '../assets/images/404.json';

function NotFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Image404,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div>
      <div className='image404Box'>
        <Lottie options={defaultOptions} width={'100%'} isClickToPauseDisabled={true} />
      </div>
      <p className='pageNotFoundText'>Page Not Found!</p>
    </div>
  );
}

export default NotFound;
