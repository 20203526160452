export const MetaRxLogo = "https://assets.omnicuris.com/metarx/MetaRx-Logo.png"

export const getNumberOrText = (value, keyCode, isNumber = false) => {
  let outputValue = ""
  if (value === undefined) {
    return outputValue
  }

  if (value.length >= 1 && keyCode != 8) {
    let regEx = isNumber ? /[A-Za-z\s.]+/gi : /[A-Za-z\s.]+/gi
    let inputValue = value.match(regEx)
    if (inputValue) {
      let name = ""
      for (let i = 0; i < inputValue.length; i++) {
        name = name + inputValue[i]
      }
      outputValue = trimForSpace(name)
    }
  } else {
    outputValue = trimForSpace(value)
  }
  return outputValue
}

export const trimForSpace = (str) => {
  if (str) {
    if (str.trim().length === 0) {
      return str.trim()
    }
  }
  return str
}

export const isAlphabets = (ev) => {
  let key = ev.keyCode
  if (!(key === 32 || key === 9 || key === 8 || key === 46 || (key >= 35 && key <= 40) || (key >= 65 && key <= 90))) {
    ev.preventDefault()
  }
}

export const isValidEmail = (value) => {
  var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
  if (value.match(mailformat)) {
    return true
  } else {
    return false
  }
}

export const isVideo = (url) => {
  const videos = ["mp4", "3gp", "ogg", "mkv", "mov", "flv", "avi", "webm", "wmv"]
  const extension = url && url.toLowerCase().split(/[#?]/)[0].split(".").pop()
  return videos.includes(extension)
}

export const isPdf = (url) => {
  const isPdf = url && url.split(/[#?]/)[0].split(".").pop().trim()
  if (isPdf === "pdf") {
    return true
  } else {
    return false
  }
}

export const validateUPI = (id) => {
  const regex = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/
  return regex.test(id)
}

export const isNumberic = (ev) => {
  let iKeyCode = ev.which ? ev.which : ev.keyCode
  if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57)) {
    ev.preventDefault()
  }
}
