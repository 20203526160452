let BASE_URL
let COMMONS_SERVICE_BASE_URL

if (process.env.REACT_APP_ENV === "development") {
  BASE_URL = "https://stgapi.omnicuris.com/"
  COMMONS_SERVICE_BASE_URL = "https://stg-commons-api.omnicuris.com/api/"
}
if (process.env.REACT_APP_ENV === "preProduction") {
  BASE_URL = "https://preprodapi.omnicuris.com/"
  COMMONS_SERVICE_BASE_URL = "https://preprod-commons-api.omnicuris.com/api/"
}
if (process.env.REACT_APP_ENV === "production") {
  BASE_URL = "https://api.omnicuris.com/"
  COMMONS_SERVICE_BASE_URL = "https://commons-api.omnicuris.com/api/"
}

export { BASE_URL, COMMONS_SERVICE_BASE_URL }

export const APP_VERSION = "1.0.0"
