export const AppStorageKeys = {
	userAccessToken: 'userAccessToken',
	entAuthRefereshToken: 'entAuthRefereshToken',
	userName: 'userName',
	userId: 'userId',
	userEmail: 'userEmail',
	profilePic: 'profilePic',
};

class LocalStorage {
	get = (key, parseJson) => {
		let data = localStorage.getItem(key) || null;
		if (data && parseJson) {
			data = JSON.parse(data);
		}
		return data;
	};
	save = (key, data) => {
		localStorage.setItem(key, JSON.stringify(data));
	};
	deleteItem = key => {
		localStorage.removeItem(key);
	};
	deleteAll = () => {
		localStorage.clear();
	};
}
export const AppLocalStorage = new LocalStorage();

class SessionStorage {
	get = (key, parseJson) => {
		let data = sessionStorage.getItem(key) || null;
		if (data && parseJson) {
			data = JSON.parse(data);
		}
		return data;
	};
	save = (key, data) => {
		sessionStorage.setItem(key, JSON.stringify(data));
	};
	deleteItem = key => {
		sessionStorage.removeItem(key);
	};
	deleteAll = () => {
		sessionStorage.clear();
	};
}

export const AppSessionStorage = new SessionStorage();


