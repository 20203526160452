import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import BookingImg from '../assets/images/bookingImg.png';
import TickIcon from '../assets/images/TickIconGreen.svg';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input';
import { isAlphabets, isValidEmail } from '../utilities';
import { bookDemoService } from '../services/apiServices';

const { TextArea } = Input;
const MANDATORY_FIELDS_ERROR_MESSAGE = 'Please fill this mandatory field!';
const EMAIL_ERROR_MESSAGE = 'Please enter a valid email address!';
const FormItem = Form.Item;
const REQUIRED = <span className='required'>*</span>;

const initFields = {
  name: '',
  companyName: '',
  email: '',
  mobileNumber: '',
  requirementText: '',
};

function BookADemo(props) {
  const { visible, setVisible } = props;
  const [visibleThankYouModal, setVisibleThankYouModal] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [formsValues, setFormsValues] = useState(initFields);
  const [isError, setIsError] = useState(false);
  const [focused, setFocused] = useState({ field: '', isFocused: false });
  const [emailError, setEmailError] = useState('');
  const [currentCountry, setCurrentCountry] = useState('');

  const formRef = useRef(null);

  let secondsToGo = 5;

  useEffect(() => {
    formRef.current && formRef.current.resetFields();
    setMobileNumberError('');
    setIsError(false);
    const dpcc = localStorage.getItem('dpcc');
    if (dpcc !== null) {
      const dpccParse = JSON.parse(dpcc);
      setCountryCode(dpccParse.phoneCode);
      setCurrentCountry(dpccParse.country);
    }
  }, [visible, formRef]);

  const onChange = (ev) => {
    const { name, value } = ev.target;
    setFormsValues({ ...formsValues, [name]: value.replace(/  +/g, ' ') });
  };

  const preventSpace = (input) => {
    if (/^\s/.test(input.value)) input.value = '';
  };

  const onBlur = () => {
    setFocused({ isFocused: false });
    if (formsValues.email !== '' && !isValidEmail(formsValues.email)) {
      setEmailError(EMAIL_ERROR_MESSAGE);
    } else {
      setEmailError('');
    }
    if (formsValues.mobileNumber && formsValues.mobileNumber.length > 7 && !isValidPhoneNumber(formsValues.mobileNumber)) {
      setMobileNumberError(`Oops! That doesn't like a valid phone number!`);
    } else {
      setMobileNumberError('');
    }
  };

  const handleSubmit = (ev) => {
    if (validateForm()) {
      setIsError(true);
      return false;
    } else {
      let obj = {};
      for (const property in formsValues) {
        obj[property] = formsValues[property].trim();
      }
      bookDemoService(obj)
        .then((response) => {
          handleOk();
        })
        .catch((error) => {
          handleOk();
        });
    }
  };

  const handlePhoneInput = (value) => {
    setFormsValues({ ...formsValues, mobileNumber: value });
    if (value && value.length > 7 && isValidPhoneNumber(value)) {
      const phoneNumber = parsePhoneNumber(value);
    }
  };

  const handleCountryChange = (country) => {
    if (country) {
      setCountryCode(`+${getCountryCallingCode(country)}`);
    }
  };

  const validateForm = () => {
    return formsValues.name === '' || formsValues.companyName === '' || !isValidPhoneNumber(formsValues.mobileNumber) || !isValidEmail(formsValues.email);
  };

  const handleOk = () => {
    setVisible(false);
    setVisibleThankYouModal(false);
    setFormsValues(initFields);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleThankYouModal(false);
    setFormsValues(initFields);
  };

  const timer = setInterval(() => {
    secondsToGo -= 1;
  }, 1000);

  setTimeout(() => {
    clearInterval(timer);
    setVisibleThankYouModal(false);
  }, secondsToGo * 1000);

  return (
    <Fragment>
      <Modal title={''} footer={null} visible={visible} onOk={handleOk} onCancel={handleCancel} closeIcon={null} className='bookingModal' bodyStyle={{ padding: 0 }} width={510} centered>
        <header className='formHeader'>
          <h3>Request for Call Back</h3>
          <img src={BookingImg} alt='' />
        </header>
        <section className='formBody'>
          <FormItem validateStatus={isError && !formsValues.name ? 'error' : 'success'} help={isError && !formsValues.name ? MANDATORY_FIELDS_ERROR_MESSAGE : ''}>
            <label for='name'>Name{REQUIRED}</label>
            <Input
              id='name'
              name='name'
              placeholder='Enter your name'
              allowClear
              value={formsValues.name}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={isAlphabets}
              onInput={(ev) => preventSpace(ev.target)}
            />
          </FormItem>
          <FormItem validateStatus={isError && !formsValues.companyName ? 'error' : 'success'} help={isError && !formsValues.companyName ? MANDATORY_FIELDS_ERROR_MESSAGE : ''}>
            <label for='companyName'>Company Name{REQUIRED}</label>
            <Input
              id='companyName'
              name='companyName'
              placeholder='Enter company name'
              allowClear
              value={formsValues.companyName}
              onChange={onChange}
              onBlur={onBlur}
              onInput={(ev) => preventSpace(ev.target)}
            />
          </FormItem>
          <FormItem
            validateStatus={emailError || (isError && !formsValues.email) ? 'error' : 'success'}
            help={emailError ? emailError : isError && !formsValues.email ? MANDATORY_FIELDS_ERROR_MESSAGE : ''}
          >
            <label for='email'>Email{REQUIRED}</label>
            <Input id='email' name='email' placeholder='Enter email address' allowClear value={formsValues.email} onChange={onChange} onBlur={onBlur} onInput={(ev) => preventSpace(ev.target)} />
          </FormItem>
          <FormItem
            validateStatus={mobileNumberError || (isError && !formsValues.mobileNumber) ? 'error' : 'success'}
            help={mobileNumberError ? mobileNumberError : isError && !formsValues.mobileNumber ? MANDATORY_FIELDS_ERROR_MESSAGE : ''}
          >
            <label for='mobileNumber'>Mobile Number{REQUIRED}</label>
            <PhoneInput
              placeholder='Enter mobile number'
              defaultCountry={currentCountry}
              value={formsValues.mobileNumber}
              id='mobileNumber'
              name='mobileNumber'
              onChange={handlePhoneInput}
              onCountryChange={handleCountryChange}
              onBlur={onBlur}
              style={{ border: isError && !formsValues.mobileNumber ? '1px solid #ff4d4f' : '' }}
            />
            <span className='countryCode'>{countryCode}</span>
            <span className='countrySelectArrow'>
              <DownOutlined />
            </span>
          </FormItem>
          <FormItem>
            <label for='requirementText'>Brief your requirement</label>
            <TextArea
              id='requirementText'
              name='requirementText'
              placeholder='Enter your requirement here'
              rows={4}
              allowClear
              value={formsValues.requirementText}
              onChange={onChange}
              onBlur={onBlur}
              onInput={(ev) => preventSpace(ev.target)}
            />
          </FormItem>
          <div className='buttons'>
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button onClick={handleSubmit} type='primary'>
              Submit
            </Button>
          </div>
        </section>
      </Modal>
      <Modal title={''} footer={null} visible={visibleThankYouModal} onOk={handleOk} onCancel={handleCancel} closeIcon={null} className='bookingModal' width={370} centered>
        <section className='thankYouMessage'>
          <img src={TickIcon} alt='' />
          <h2>Thanks for contacting us. We will get back to you soon!</h2>
        </section>
      </Modal>
    </Fragment>
  );
}

export default BookADemo;
