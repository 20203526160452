import React, {useState} from "react"
import ArrowIcon from "../assets/images/ArrowIconWhite.svg"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {isValidEmail} from "../utilities"
import {sendOtpService} from "../services/apiServices"
import {LoadingOutlined} from "@ant-design/icons"
import TickIcon from "../assets/images/TickIconGreen.svg"
import {Modal} from "antd"
import {MdClose} from "react-icons/md"
import BottomBgImg from "../assets/images/clipboard-bg.png"

function TopSectionPrescriptionAudit({
                                         setOtpErrorMessage,
                                         isEmail,
                                         setIsEmail,
                                         formValue,
                                         setFormValue,
                                         disabledForm,
                                         setUserId,
                                         setDisabledForm
                                     }) {
    const [loading, setLoading] = useState(false)
    const [uploadMessage, setUploadMessage] = useState("")
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false)

    const sendOtp = () => {
        setLoading(true)
        const payload = isEmail ? {email: formValue.trim()} : {mobileNumber: formValue.trim().substring(2)}
        sendOtpService(payload)
            .then((response) => {
                if (response.hasUploaded) {
                    setUploadMessage(response.message)
                    setOpenPrescriptionModal(true)
                } else {
                    setDisabledForm(true)
                    setUserId(response.userId)
                    localStorage.setItem("userName", response.userName)
                    localStorage.setItem("isPanCardRequired", response.isPanCardRequired)
                }
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                if (error.response?.data?.message) {
                    setOtpErrorMessage(error.response.data.message)
                    setDisabledForm(true)
                }
            })
    }

    const handleSwitch = () => {
        setFormValue("")
        setIsEmail(!isEmail)
    }

    const validateForm = () => {
        return disabledForm || (isEmail ? !formValue || !formValue.trim() || !isValidEmail(formValue) : !formValue || formValue.length < 12)
    }

    const handleClose = () => {
        window.location.reload()
        setOpenPrescriptionModal(false)
    }

    return (
        <section className="topSection prescription-audit-top-section">
            <article>
                <h1>Prescription Audit</h1>
                <p>MetaRx presents Prescription Audit—a powerful tool that generates insights and trends on prescription
                    brand share, providing a comprehensive analysis to optimize market strategies and enhance brand
                    positioning.</p>
                <div className="form-container" style={disabledForm ? {cursor: "no-drop", opacity: 0.5} : {}}>
                    <div className="prescription-audit-form">
                        {!isEmail ? (
                            <div style={{position: "relative"}} className="mobile-form-controls">
                                <PhoneInput country={"in"} countryCodeEditable={false} disableDropdown={true}
                                            value={formValue} onChange={setFormValue}
                                            placeholder="Enter mobile number"/>
                                {!formValue && <span className="place-holder-text">Enter mobile number</span>}
                            </div>
                        ) : (
                            <input type="email" placeholder="Enter email address" value={formValue}
                                   onChange={(ev) => setFormValue(ev.target.value)} className="form-controls"/>
                        )}
                        <div className="or-separator">
                            <span>Or</span>
                        </div>
                        <button disabled={disabledForm} onClick={handleSwitch} className="link-button">
                            {isEmail ? "Use mobile number instead" : "Use email instead"}
                        </button>
                    </div>
                    <button onClick={sendOtp} disabled={validateForm()} className="green-button">
                        Continue to verify {loading ? <LoadingOutlined style={{marginLeft: 16}}/> :
                        <img src={ArrowIcon} alt/>}
                    </button>
                </div>
                {disabledForm && (
                    <span onClick={() => setDisabledForm(false)} className="reset-from">
            Reset
          </span>
                )}
            </article>
            <Modal title={""} footer={null} open={openPrescriptionModal} onOk={handleClose} onCancel={handleClose}
                   closeIcon={<MdClose/>} className="bookingModal" width={370} centered>
                <section className="thankYouMessage">
                    <img src={TickIcon} alt=""/>
                    <h2>{uploadMessage}</h2>
                </section>
            </Modal>
            <div className="bottom-bg">
                <img src={BottomBgImg} loading="lazy" alt=""/>
            </div>
        </section>
    )
}

export default TopSectionPrescriptionAudit
