import React, { useState } from 'react';
import ArrowIcon from '../assets/images/ArrowIconWhite.svg';
import BgImage from '../assets/images/top-cover-bg.png';
import BookADemo from './BookADemo';

function TopSection() {
  const [visible, setVisible] = useState(false);
  return (
    <section className='topSection'>
      <article>
        <h1>Give your prescriptions the precision they deserve</h1>
        <p>With MetaRx, you can enhance prescription accuracy, optimize audits, and ensure the highest standards in patient care.</p>
        <button onClick={() => setVisible(true)} className='bookDemoButton'>
          Send Your Query <img src={ArrowIcon} alt />
        </button>
      </article>
      <BookADemo visible={visible} setVisible={setVisible} />
    </section>
  );
}

export default TopSection;
