import React, { useEffect, useState } from "react"
import BookADemo from "./BookADemo"
import axios from "axios"
import { getCountriesService } from "../services/apiServices"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { MetaRxLogo } from "../utilities"

function Header() {
  const [visible, setVisible] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const history = useHistory()

  useEffect(() => {
    fetchCurrentCountry()
    if (!window.location.href.includes("/user/confirm")) {
      window.addEventListener("scroll", listenScrollEvent)
    } else {
      setIsScrolled(true)
    }
  }, [])

  const fetchCurrentCountry = () => {
    axios
      .get("https://api.country.is/")
      .then((response) => {
        fetchCountries(response.data.country)
      })
      .catch((error) => console.log(error))
  }

  const fetchCountries = (cc) => {
    getCountriesService().then((response) => {
      const country = response.countries.find((items) => items.code === cc)
      const data = { country: country.code, phoneCode: `+${country.phoneCode}` }
      localStorage.setItem("dpcc", JSON.stringify(data))
    })
  }

  const listenScrollEvent = (e) => {
    const header = document.getElementById("header")
    if (window.scrollY > 10) {
      header.classList.add("headerBg")
      setIsScrolled(true)
    } else {
      header.classList.remove("headerBg")
      setIsScrolled(false)
    }
  }

  const goToFeatures = () => {
    const featureElement = document.getElementById("features")
    featureElement.scrollIntoView({ behavior: "smooth", block: "center" })
  }
  const goToFooter = () => {
    const footerElement = document.getElementById("footer")
    footerElement.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  return (
    <header id="header" className={`header ${history.location.pathname !== "/" ? "header-wrapper" : ""}`}>
      <div className="appLogo">
        <img src={MetaRxLogo} alt="logo" />
      </div>
      <nav className="headerNav">
        {history.location.pathname === "/" && (
          <>
            <a onClick={goToFeatures}>Features</a>
            <a onClick={goToFooter}>Contact</a>
          </>
        )}
        <a onClick={() => setVisible(true)}>Send Your Query</a>
      </nav>
      <BookADemo visible={visible} setVisible={setVisible} />
    </header>
  )
}

export default Header
